import { useState, useEffect } from 'react';
import '../../styles/cart.css';
import { IoMdClose, IoIosRefresh } from "react-icons/io";



const CheckoutItem = ({
	item,
	count,
	render,
	setRender,
}) => {

	const [formData, setFormData] = useState({
		item_count: 1
	});
	const { item_count } = formData;

	useEffect(() => {
		if (count) {
		setFormData({...formData, item_count: count});
	}
	}, [count]);

	return (
		<div className='container-cart-item'>
			<div className='cart-item-image'>
				<img src={item.good.main_image} />
			</div>
			<div className='checkout-item-info'>
				<h3>Código QR Grabado láser</h3>
				<div className='checkout-item-header'>
					<p>{item.good.name}</p>
					<p>$ {item.good.price}</p>
					<p>Cantidad: {item.count}</p>
				</div>
			</div>
		</div>
	)
}

export default CheckoutItem