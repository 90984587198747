import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { reset_password_confirm } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Navigate } from 'react-router';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const ResetPasswordConfirm = ({
 	reset_password_confirm, loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const params = useParams()

	const [requestSent, setRequestSent] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [rePasswordType, setRePasswordType] = useState('password');
	const [reIconShow, setReIconShow] = useState(false);
	const [formData, setFormData] = useState({
		new_password: '',
		re_new_password: ''
	});


	const {
		new_password,
		re_new_password
	} = formData;


	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	const uid = params.uid
    	const token = params.token

    	reset_password_confirm(uid, token, new_password, re_new_password)
    	if (new_password === re_new_password) {
    		setRequestSent(true);
    	}
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  		
  	}

  	const handleShowRePassword = () => {
  		if (rePasswordType === 'password') {
  			setRePasswordType('text')
  			setReIconShow(!reIconShow)
  		}
  		else if (rePasswordType === 'text') {
  			setRePasswordType('password')
  			setReIconShow(!reIconShow)
  		}
  		
  	}

  	if (requestSent && !loading)
  		return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-reset-password'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
					<h3 className='form-title'>Escriba su nueva contraseña</h3>
					<div className='form-section-cont'>
						<label htmlFor='password'>Nueva contraseña</label>
						<input
						name='new_password'
						value={new_password}
						onChange={e => onChange(e)}
						type='password'
						required
						className='input-form' />
						{iconShow ? (
							<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
						) : (
							<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
						)}
					</div>
					<div className='form-section-cont'>
						<label htmlFor='re_password'>Repita la nueva contraseña</label>
						<input
						name='re_new_password'
						value={re_new_password}
						onChange={e => onChange(e)}
						type='password'
						required
						className='input-form' />
						{reIconShow ? (
							<FaRegEyeSlash onClick={handleShowRePassword} className='password-show-hide' />
						) : (
							<FaRegEye onClick={handleShowRePassword} className='password-show-hide' />
						)}
					</div>
					{loading ? 
						<button type='button' className='button-form'><Oval
	  						visible={true}
							height="20"
							width="20"
							color="#ddd"
							ariaLabel="oval-loading"
							secondaryColor="#ddd"
							wrapperStyle={{}}
							wrapperClass=""
							/>
						</button> : 
						<button type='submit' className='button-form'>
							Cambiar
						</button>
					}
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	reset_password_confirm 
}) (ResetPasswordConfirm)