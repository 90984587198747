import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { reset_password } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import '../../styles/auth.css';


const ResetPassword = ({
 	reset_password, loading
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [requestSent, setRequestSent] = useState(false);
	const [formData, setFormData] = useState({
		email: ''
	});


	const {
		email
	} = formData;


	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	reset_password(email);
    	setRequestSent(true);
  	};

  	if (requestSent && !loading)
  		return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-reset-password'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
					<h3 className='form-title'>Recupera tu contraseña</h3>
					<div className='form-section-cont'>
						<label htmlFor='email'>Correo Electrónico</label>
						<input
						name='email'
						value={email}
						onChange={e => onChange(e)}
						type='email'
						required
						className='input-form' />
					</div>
					{loading ? 
						<button type='button' className='button-form'><Oval
	  						visible={true}
							height="20"
							width="20"
							color="#ddd"
							ariaLabel="oval-loading"
							secondaryColor="#ddd"
							wrapperStyle={{}}
							wrapperClass=""
							/>
						</button> : 
						<button type='submit' className='button-form'>
							Enviar email
						</button>
					}
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	reset_password 
}) (ResetPassword)