import {
	UPLOAD_GOOD_SUCCESS,
	UPLOAD_GOOD_FAIL,
	GET_GOODS_SUCCESS,
	GET_GOODS_FAIL,
	GET_GOOD_SUCCESS,
	GET_GOOD_FAIL,
	MODIFY_GOOD_SUCCESS,
	MODIFY_GOOD_FAIL,
	SET_GOOD_LOADING,
	REMOVE_GOOD_LOADING,
	GET_PROFILE_GOOD_SUCCESS,
	GET_PROFILE_GOOD_FAIL,
} from './types'
import { setAlert } from './alert';
import axios from 'axios'
import React from 'react';


export const upload_good = (formData, imageData) => async dispatch => {
    dispatch({
        type: SET_GOOD_LOADING
    });

	if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Content-Type': 'multipart/form-data'
            }
        };

    	const sendData = new FormData();
        sendData.append('name', formData.name);
	    sendData.append('description', formData.description);
	    if (imageData.mainImage) {
	        sendData.append('main_image', imageData.mainImage.image);
	    }
	    if (imageData.backImage) {
	        sendData.append('back_image', imageData.backImage.image);
	    }
	    sendData.append('brand', formData.brand);
	    sendData.append('model', formData.model);
	    sendData.append('serial', formData.serial);
	    sendData.append('color', formData.color);
	    sendData.append('size', formData.size);

	    try {
	        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/good/upload`, sendData, config);

	        if (res.status === 201) {
	            dispatch({
	                type: UPLOAD_GOOD_SUCCESS,
	                payload: res.data
	            });
	            dispatch(setAlert('Tu bien ha sido registrado correctamente', 'good'))
			} else {
	            dispatch({
	                type: UPLOAD_GOOD_FAIL
	            });
	            dispatch(setAlert('Error al registrar el bien.', 'bad'))
			}
	        dispatch({
	            type: REMOVE_GOOD_LOADING
	        });
		} catch (err) {
	        dispatch({
	            type: UPLOAD_GOOD_FAIL
	        });
	        dispatch({
	            type: REMOVE_GOOD_LOADING
	        });
	        dispatch(setAlert('Error conectando con el servidor, intenta mas tarde', 'bad'))
		}
	} else {
		dispatch({
    	    type: REMOVE_GOOD_LOADING
    	});
    	dispatch({
	        type: UPLOAD_GOOD_FAIL
	    });
    	dispatch(setAlert('Error de autenticación, inicia sesión antes de continuar', 'bad'))
	};
};


export const get_goods = () => async dispatch => {
	if(localStorage.getItem('access')){
	    const config = {
	        headers: {
	        	'Authorization': `JWT ${localStorage.getItem('access')}`,
	            'Accept': 'application/json'
	        }
	    };

	    try {
	        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/good/get_goods?sortBy=date_created&order=desc&limit=10`, config);
	    
	        if (res.status === 200) {
	            dispatch({
	                type: GET_GOODS_SUCCESS,
	                payload: res.data
	            });
	        } else {
	            dispatch({
	                type: GET_GOODS_FAIL
	            });
	        }
	    } catch(err) {
	        dispatch({
	            type: GET_GOODS_FAIL
	        });
	    }
    } else {
        dispatch({
            type: GET_GOODS_FAIL
        });
        dispatch(setAlert('Error de autenticación, inicia sesión antes de continuar', 'bad'))
    }
};


export const get_good = (goodId) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/good/${goodId}`, config);
    
        if (res.status === 200) {
            dispatch({
                type: GET_GOOD_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_GOOD_FAIL
            });
        }
    } catch(err) {
        dispatch({
            type: GET_GOOD_FAIL
        });
    }
};


export const get_profile_good = (goodId) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/good/${goodId}/profile`, config);
    
        if (res.status === 200) {
            dispatch({
                type: GET_PROFILE_GOOD_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PROFILE_GOOD_FAIL
            });
        }
    } catch(err) {
        dispatch({
                type: GET_PROFILE_GOOD_FAIL
            });
    }
};


export const modify_good = (goodModifyId, formNewData, imageNewData) => async dispatch => {
    dispatch({
        type: SET_GOOD_LOADING
    });

	if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        console.log("Se esta enviando la solicitud")

    	const modifyData = new FormData();
        modifyData.append('name', formNewData.name);
	    modifyData.append('description', formNewData.description);
	    if (imageNewData.mainImage) {
	        modifyData.append('main_image', imageNewData.mainImage.image);
	    }
	    if (imageNewData.backImage) {
	        modifyData.append('back_image', imageNewData.backImage.image);
	    }
	    modifyData.append('brand', formNewData.brand);
	    modifyData.append('model', formNewData.model);
	    modifyData.append('serial', formNewData.serial);
	    modifyData.append('color', formNewData.color);
	    modifyData.append('size', formNewData.size);
	

	    try {
	        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/good/${goodModifyId}/modify`, modifyData, config);

	        if (res.status === 200) {
	            dispatch({
	                type: MODIFY_GOOD_SUCCESS,
	                payload: res.data
	            });
	            dispatch(setAlert('Tu bien ha sido modificado correctamente', 'good'))
			} else {
	            dispatch({
	                type: MODIFY_GOOD_FAIL
	            });
	            dispatch(setAlert('Error al modificar el bien.', 'bad'))
			}
	        dispatch({
	            type: REMOVE_GOOD_LOADING
	        });
		} catch (err) {
	        dispatch({
	            type: MODIFY_GOOD_FAIL
	        });
	        dispatch({
	            type: REMOVE_GOOD_LOADING
	        });
	        dispatch(setAlert('Error conectando con el servidor, intenta mas tarde', 'bad'))
		}
	} else {
		dispatch({
    	    type: REMOVE_GOOD_LOADING
    	});
    	dispatch({
	        type: MODIFY_GOOD_FAIL
	    });
    	dispatch(setAlert('Error de autenticación, inicia sesión antes de continuar', 'bad'))
	};
};