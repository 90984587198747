import Layout from '../../hocs/Layout';
import { FaCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const TermsAndConditions =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Registro de la Propiedad de Bienes Muebles</h2>
					<h3>TÍTULO I.</h3>
					<h4>Del Registro.</h4>
					<p>
						Art. 1º - El Sr. Carlos Ariel BOTTA, D.N.I. N º 20.998.922, y/o quién él autorice en un futuro, será el organismo de 
						aplicación del presente régimen, y tendrá a su cargo el registro de bienes muebles, conforme a la denuncia que de los mismos 
						efectúe quien solicite la inscripción.
						
					</p>
					<p>
						El Sr. Carlos Ariel BOTTA y/o quien él autorice en un futuro reglará la organización y el funcionamiento del mencionado 
						registro, conforme a los medios y procedimientos técnicos más adecuados para el mejor cumplimiento de sus fines. 
						Asimismo, podrá modificar sus límites territoriales de competencia a otras comunas o municipios, a través de los 
						acuerdos con los mismos que pudieran efectuarse a futuro.
					</p>
					<p>
						En el Registro se inscribirá, de modo libre y voluntario por parte de los interesados, el dominio de cosas muebles, sus 
						modificaciones, su extinción, sus transmisiones y gravámenes. También se anotarán en ellos los embargos y otras medidas 
						cautelares, las denuncias de robo o hurto y demás actos que prevea este cuerpo legal o su reglamentación. En ningún caso 
						podrá interpretarse que la inscripción obedece a órdenes, directivas, imposiciones, sugerencias vinculantes, exigencias, 
						condiciones o cualquier otro motivo emanado del Sr. Botta.
					</p>
					<p>
						Art. 2º - El Sr. Carlos Ariel Botta y/o quien él autorice en un futuro, controlará el funcionamiento del Registro, 
						realizará las tareas registrales especificas que determine la reglamentación y dispondrá el archivo ordenado de copias 
						de los instrumentos que se registren.
					</p>
					<p>
						Art 3º - Los trámites que se realicen ante el Registro de la Propiedad de Bienes Muebles deberán abonar el arancel que 
						fije el Organismo de Aplicación, salvo los casos expresamente exceptuados por la reglamentación. No podrá restringirse 
						o limitarse la inmediata inscripción del dominio de cosas muebles por nomas de carácter administrativo ajenas a los 
						aranceles del Registro, Las personas físicas o jurídicas registradas en el Organismo de Aplicación como comerciantes 
						habituales en la compraventa de cosas muebles, podrán inscribir a su nombre los bienes muebles usados que adquieran 
						para posterior reventa. En tal caso no abonarán arancel alguno por el acto y por su inscripción, siempre que dentro 
						de los (90) días contados desde esta última, la reventa se realice e inscriba. Si ello no ocurre, el arancel se deberá 
						abonar dentro de los CINCO (5) días de vencido dicho plazo. El Organismo de Aplicación establecerá los requisitos que 
						deberán cumplir los interesados para inscribirse como comerciantes habituales en la compraventa de bienes muebles y las 
						causas por las cuales se suspenderá o cancelará esa inscripción.
					</p>
					<p>
						Art. 4º - Los pedidos de inscripción o anotación en el Registro, y en general los trámites que se realicen ante él, 
						solo podrán efectuarse mediante la utilización de las solicitudes tipo que determine el Organismo de Aplicación, el que 
						fijara su contenido y demás requisitos de validez. Cuando las solicitudes tipo no se suscribieron por los interesados 
						ante el encargado de registro, deberán presentarse con las firmas certificadas en la forma y por las personas que 
						establezca el Organismo de Aplicación. Dichas solicitudes serán expedidas gratuitamente por el Organismo de Aplicación.
					</p>
					<p>
						Art 5º - Los contratos de transferencia de cosas muebles que se formalicen por instrumento privado, se inscribirán en el 
						registro mediante la utilización de las solicitudes tipo mencionadas en el articulo anterior, suscriptos por las partes. 
						Cuando la transferencia se formalice por instrumento publico o haya sido dispuestas por orden judicial, se presentará para 
						su inscripción junto con copia certificada de la sentencia judicial que lo dispone o acta notarial. En las transferencias 
						dispuestas por autoridad judicial, se transcribirá textualmente la parte pertinente del auto que la ordena.
					</p>
					<p>
						Art. 6º - Por el servicio de conservación, datos personales y bienes muebles registrados, el sistema percibirá un monto 
						dinerario, establecido por el mismo sistema sin remuneración alguna para el franquiciado.
					</p>
					<p>
						Art. 7º - A los efectos de la buena fe en las inscripciones a efectuarse en el Registro, se presume que los que inscriben 
						son titulares de los bienes a inscribir, aun cuando el titular o del disponente del bien no cuente con el título de la 
						adquisición del bien. El registro otorgará al titular del bien un certificado de las constancias de su inscripción y demás 
						anotaciones que existen el que tendrá una validez hasta el momento en que el bien sea transferido, perdido o se destruya.
					</p>
					<p>	
						Art. 8º - En caso de pérdida, extravío o destrucción involuntaria, deficiente conservación o alteración material derivada 
						exclusivamente del certificado de inscripción o en cualquier otro caso, en que, sin mediar la comisión de un delito, dicho 
						documento quedara en condiciones ilegibles o motivara dudas acerca de su legitimidad, el Registro expedirá un duplicado 
						con indicación de la causa, y constancias de todas las inscripciones vigentes en el registro, debiendo en su caso, retener 
						el ejemplar inutilizado.
					</p>
					<h3>TÍTULO II.</h3>
					<h4>De los Bienes que pueden inscribirse.</h4>
					<p>
						Art. 9º - Se entiende por bienes o cosas muebles aquellas que pueden desplazarse por si mismas o por una fuerza externa. 
						Quedan excluidos del presente régimen los bienes regidos por el Decreto Ley 6582/58, ratificada por la ley No 14.467 
						(Decreto Nº 4560/73), los animales cualquiera sea su especie o denominación, semovientes y todo bien mueble que tenga un 
						régimen jurídico de registración especial previsto por nuestro ordenamiento legal. Asimismo quedan excluidos los bienes 
						fuera del comercio cuya transmisión esta expresamente por Ley o por actos jurídicos previstos por el código civil y 
						comercial de la nación. Quedan excluidos de este régimen los bienes pertenecientes al dominio publico y los pertenecientes 
						al dominio privado del estado.
					</p>
					<p>
						Art 10º - A modo ejemplificativo y no taxativo podrán ser inscriptos los siguientes bienes:
					</p>
					<p>
						•	ACCESORIOS PARA VEHICULOS (balizas, gatos, llaves cruz, etc.)<br />
						•	ACONDICIONADOR DE AIRE<br />
						•	ARMARIOS, GAVETAS, ESTANTES Y, ANAQUELES (vitrinas, armarios de cocina, anaqueles de compartimientos)<br />
						•	ARTEFACTOS NO ELÉCTRICOS (linternas, faroles, etc.)<br />
						•	ARTÍCULOS DE USO DOMESTICO (sábanas, almohadas, mantas, frazadas, colchones y otras ropas de cama, biombos, etc.)<br />
						•	ARTÍCULOS Y UTILES VARIOS DE USO DOMÉSTICO (jarrones, floreros, máquinas de coser y de tejer de uso doméstico, etc.)<br />
						•	ATRILES Y CABALLETES PARA ARTE<br />
						•	AUDÍFONOS<br />
						•	AURICULARES, MICROTELÉFONOS, MICRÓFONOS Y PARLANTES <br />
						•	AYUDA Y DISPOSITIVOS PARA INSTRUCCIÓN EN EL AULA (maquetas, cuerpos geométricos, cuadros alusivos, equipo didáctico, láminas, punteros)<br />
						•	AZADAS (de jardín)<br />
						•	BALDES (para construcción)<br />
						•	BANCOS (de carpinteros, mecánicos, etc.)<br />
						•	BANCOS SIMPLES Y DOBLES (de jardín. Láminas, punteros)<br />
						•	BANDERAS Y GALLARDETES (banderas de señales, asta de banderas, puntas de astas, bandas, tahalí, etc.)<br />
						•	BÁSCULAS Y BALANZAS<br />
						•	BATERÍAS (recargables)<br />
						•	BIBLIOTECAS<br />
						•	BICICLETAS (incluidas las que poseen motor)<br />
						•	BOMBAS DE AGUA, eléctricas y manuales<br />
						•	BORDEADORAS DE CÉSPED<br />
						•	BOTINEROS Y BAULES<br />
						•	BOTIQUÍN<br />
						•	BRÚJULAS<br />
						•	BUSTOS<br />
						•	CACEROLAS<br />
						•	CAJAS PARA HERRAMIENTAS Y FERRETERÍAS<br />
						•	CAJAS Y CAJONES<br />
						•	CALENTADORES<br />
						•	CÁMARAS Y FILMADORAS<br />
						•	CÁMARAS FOTOGRÁFICAS<br />
						•	CAMILLAS<br />
						•	CAMPANAS<br />
						•	CARPAS Y TOLDOS<br />
						•	CARRETELES Y BOBINAS<br />
						•	CARRETILLAS Y CARROS<br />
						•	CASAS PREFABRICADAS<br />
						•	CASSETTES<br />
						•	CENICEROS<br />
						•	CEPILLADORAS Y PERFILADORAS<br />
						•	CESTOS (para papeles, basura, etc.)<br />
						•	CHALECOS SALVAVIDAS INFLABLES (balsas salvavidas)<br />
						•	CILINDRO PARA GAS (garrafas)<br />
						•	CIRCULADORES DE AIRE<br />
						•	COBERTURAS PARA PISOS (alfombras, felpudos)<br />
						•	COCINAS (a leña, a gas)<br />
						•	COFRES<br />
						•	COMPRESORES<br />
						•	COMPUTADORAS<br />
						•	CONTENEDORES DE USO GENERAL (tachos, cestos para basura, cestos p/ropa, balde plástico, tachos p/riego, canastos uso doméstico)<br />
						•	CORTADORAS DE CÉSPED<br />
						•	CORTINADOS (cortinas, materiales p/cortinados)<br />
						•	COSTUREROS<br />
						•	CRISTALERIA (copas, vasos, jarras, etc.)<br />
						•	CUADROS (próceres alusivos)<br />
						•	CUADROS (obras de arte)<br />
						•	CUBIERTAS Y CAMARAS NEUMATICAS<br />
						•	CUCHILLERIA Y VAJILLA (cuchillos, tenedores, cucharas, etc.)<br />
						•	DIAPOSITIVAS<br />
						•	DISCOS (grabaciones fotográficas)<br />
						•	DISCOS Y PIEDRAS ABRASIVAS (discos para afilar)<br />
						•	DISPOSITIVOS Y ACCESORIOS PARA OFICINAS (sacapuntas, abrochadoras, perforadoras, tijeras, saca broches, pinches p/papel, etc.)<br />
						•	EFECTOS DE COLECCIÓN (estampillas, libros valiosos, obras de arte, antigüedades, escudos, etc.)<br />
						•	EMPALIZADAS, CERCOS Y PORTONES (de madera y metálicos)<br />
						•	EQUIPAJE (botineros, portafolios, baúles y valijas)<br />
						•	EQUIPOS, ACCESORIOS Y SUMINISTROS RELIGIOSOS<br />
						•	EQUIPOS DE ATLETISMO Y DEPORTES (discos, balas, valla, equipo de béisbol, fútbol, golf, pelotas, cubre piernas p/hockey, etc.)<br />
						•	EQUIPOS DE COMUNICACIÓN DE RADIO Y TELEVISIÓN<br />
						•	EQUIPOS DE IMPRESIÓN, COPIADO Y ENCUADERNACIÓN (encuadernadoras, imprentas, mimeógrafos, maquinas fotocopiadoras)<br />
						•	EQUIPOS DE RECREACION Y GIMNASIA (toboganes, columpios, equipos p/patios y campos de recreos, elementos y equipos de gimnasia)<br />
						•	EQUIPOS DE SOLDADURA CON GAS (sopletes y boquillas, maquinas a gas para soldar con bronce)<br />
						•	EQUIPOS DE SOLDADURA POR RESISTENCIA ELÉCTRICA<br />
						•	EQUIPOS GRABADORES Y REPRODUCCIONES DE SONIDOS<br />
						•	EQUIPOS, JUEGOS Y ACCESORIOS HERRAMIENTAS DE MANO<br />
						•	EQUIPOS P/COCINAR, HORNEAR Y SERVIR COMIDA (hornos, tostadoras, planchas para bifes, parrillas, mesas p/calentar comida)<br />
						•	EQUIPOS P/CONTROL DE PLAGAS, ENFERMEDADES Y HELADAS<br />
						•	EQUIPOS P/PREPARACIÓN DE SUELOS (para cultivar, para plantar)<br />
						•	EQUIPOS PARA PURIFICACIÓN DE AGUA<br />
						•	EQUIPOS PARA REGISTROS VISIBLES (archivos p/índice, cestos de alambres y varillas)<br />
						•	EQUIPOS PARA SOLDADURA ELÉCTRICA (soldaduras automáticas y semiautomáticas para arco)<br />
						•	EQUIPOS PARA TAMBOS, AVICULTURA Y GANADERIA<br />
						•	EQUIPOS TELÉFONICOS Y TELEGRÁFICOS (interruptores)<br />
						•	EQUIPOS Y SUMINISTROS PARA LABORATORIO (cristalería, embudos, etc.)<br />
						•	EQUIPOS Y ÚTILES PARA COCINA (maquinas lavaplatos, cortadoras de fiambre, mezcladora de comida, carro de uso general, licuadoras, procesadoras, etc.)<br />
						•	ESCALERAS (de madera o metal)<br />
						•	ESCURRIDORES (tambores de secado)<br />
						•	ESTUFAS (eléctricas, a gas)<br />
						•	EXTINGUIDORES DE INCENDIO<br />
						•	EXTRACTORES<br />
						•	FAROLES<br />
						•	FONÓGRAFOS Y APARATOS DE RADIO Y TELEVISIÓN (equipos y aparatos completos de fonógrafos, radio y televisión tipo doméstico, minicomponentes)<br />
						•	FOTOCOPIADORAS<br />
						•	FREEZER<br />
						•	GARRAFAS<br />
						•	GENERADORES ELÉCTRICOS Y GRUPOS ELECTRÓGENOS<br />
						•	HELADERAS<br />
						•	HERRAMIENTAS DE MANO, CORTANTES, SIN MOTOR (sierras, escoplos, buriles, formones, limas, cortadoras de caño, escofina, dados de terrajas, hachas, hachuelas, machetes, tijera p/cortar lata, etc.)<br />
						•	HERRAMIENTAS DE MANO, IMPULSADAS CON MOTOR (perforadoras, remachadoras, sierras eléctricas manuales, herramientas neumáticas, ruedas y conos abrasivos p/herramientas de mano)<br />
						•	HERRAMIENTAS DE MANO, NO CORTANTES, SIN MOTOR (martillos, piquetas, pinzas, destornilladores, palas, rastrillos, horquillas y azadas p/construcción, barretas (patas de cabra), lámparas de soldar (sopletes), morsa, etc.)<br />
						•	HERRAMIENTAS DE MANO Y UTENSILIOS PARA COCINA (ollas, cacerolas, abrelatas, ralladores, máquina para hacer puré, amasadoras, termos, jarros, máquinas de cortar fideos, máquina de picar carne)<br />
						•	HERRAMIENTAS DE MEDICIÓN (plomadas, cintas métricas de precisión, escuadras, transportadores, compases, etc.)<br />
						•	HERRAMIENTAS Y ACCESORIOS PARA MAQUINAS DE CARPINTERIA (sierra sin fin, herramientas de cortes, bancos de carpintero)<br />
						•	HOJAS Y LIBROS DE MÚSICA<br />
						•	HORQUILLAS<br />
						•	IMPLEMENTOS Y HERRAMIENTAS PARA JARDINERÍA (tractores p/jardín, cortadoras de césped, podadores de cercos vivos, máquinas sembradoras de césped, rastrillos, horquillas y otras herramientas para jardinería, distribuidora, fertilizantes)<br />
						•	IMPRESOS VARIOS (calcomanías, carteles, etc.)<br />
						•	INSTRUMENTAL, EQUIPO Y SUMINISTRO MÉDICO Y QUIRÚRGICO (termómetros, clínicos, audífonos, equipos veterinarios, etc.)<br />
						•	INSTRUMENTAL ÓPTICO (binoculares, lupas, microscopios, telescopios)<br />
						•	INSTRUMENTAL PARA DIBUJO (instrumentos y útiles para dibujo)<br />
						•	INSTRUMENTOS MUSICALES (instrumentos de música completos, pianos, flautas, etc.)<br />
						•	INTERRUPTORES AUTOMÁTICOS <br />
						•	INTERRUPTORES ELÉCTRICOS<br />
						•	JUEGOS, JUGUETES Y RODADES (cochecitos p/niños, juegos de tableros, coches p/muñecas, juguetes manuales, juguetes p/jardín, etc.)<br />
						•	LÁMPARAS PORTÁTILES (eléctricas)<br />
						•	LAVARROPAS (maquinas lavadoras de ropa)<br />
						•	LIBROS, FOLLETOS (libros y folletos técnicos y no técnicos)<br />
						•	LICUADORAS (procesadoras, jugueras, etc.)<br />
						•	LINTERNAS<br />
						•	LUSTRADORAS Y ASPIRADORAS PARA PISOS<br />
						•	MADERAS Y MATERIALES BÁSICOS RELACIONADOS (maderas cortadas, maderas p/pisos, virutas, torneados y tallados especiales)<br />
						•	MANGUERAS, TUBOS FLEXIBLES (mangueras comunes)<br />
						•	MÁQUINAS ALESADORAS<br />
						•	MÁQUINAS ASERRADORAS Y AFILADORAS<br />
						•	MÁQUINAS BROCHADORAS<br />
						•	MÁQUINAS DE CARPINTERIA (acopladoras, espigadoras, etc.)<br />
						•	MÁQUINAS DE CONTABILIDAD Y CALCULADORAS (máquinas de sumar)<br />
						•	MÁQUINAS DE COSER Y DE TEJER<br />
						•	MÁQUINAS DE COSER CALADO<br />
						•	MÁQUINAS DE ESCRIBIR (máquinas eléctricas de oficina)<br />
						•	MÁQUINAS LAVADORAS DE ROPA<br />
						•	MÁQUINAS PARA CURVAR Y MOLDEAR<br />
						•	MÁQUINAS PERFORADORAS Y TERRAJADORAS<br />
						•	MÁQUINAS PUNZONADORAS Y CORTADORAS<br />
						•	MÁQUINAS REMACHADORAS<br />
						•	MÁQUINAS VARIAS DE OFICINA (máquinas contadoras de monedas, máquinas de manipuleo de dinero, etc.)<br />
						•	MAPAS, ATLAS, CARTAS TOPOGRÁFICAS Y GLOBOS TERRÁQUEOS<br />
						•	MÁSTILES<br />
						•	MATERIALES FOTOGRÁFICOS<br />
						•	MECHAS PARA PERFORAR, ALESAR Y FRESAR MANUALES Y MÁQUINAS<br />
						•	MESAS<br />
						•	MICROFILM PROCESADO<br />
						•	MICRÓFONOS<br />
						•	MICROSCOPIOS<br />
						•	MIMEÓGRAFOS MOLINOS (accionados por el viento)<br />
						•	MOTORES ELÉCTRICOS<br />
						•	MOTORES VARIOS Y COMPONENTES (motores accionados por el viento y por aire comprimido)<br />
						•	MUEBLES DE OFICINA (armarios p/archivo, caja de seguridad, escritorios, percheros, muebles para escuelas, etc.)<br />
						•	MUEBLES DE USO DOMÉSTICO (sillas, muebles para campaña, espejos para colgar y de apoyar, mesa de fumar, sillones, juego de sillones, banquetas, camas, etc.)<br />
						•	MUEBLES, EQUIPOS, ÚTILES Y SUMINISTROS PARA HOSPITALES (ortopedia, camillas, camas p/ hospitales, etc.)<br />
						•	MUEBLES PARA ESCUELAS (bancos simples y dobles, sillas pupitres, escritorios, mesas dobles, pizarrones, etc.)<br />
						•	MUEBLES Y ARTEFACTOS VARIOS (muebles de piedra, yeso y hormigón, muebles de auditorio y sala de espectáculos, muebles de biblioteca, mesa de comedor, caballetes, tablones, etc.)<br />
						•	MUEBLES FLOTANTES<br />
						•	PANTALLA DE PROYECCIÓN<br />
						•	PARLANTES<br />
						•	PARTES Y ACCESORIOS INSTRUMENTOS MUSICALES (atriles, estuches)<br />
						•	PELÍCULAS PROCESADAS (diapositivas)<br />
						•	PIANOS<br />
						•	PINCELES PARA PINTURA Y ARTE<br />
						•	PIZARRONES<br />
						•	PLACAS (para aplicaciones específicas)<br />
						•	PLANCHAS (para planchado de ropa)<br />
						•	PLATOS (para servicio de comida)<br />
						•	PONTONES (puentes flotantes)<br />
						•	PORTAFOLIOS<br />
						•	PRENSAS MANUALES<br />
						•	PRODUCTOS DE CARPINTERÍA (marcos de puertas y ventanas, puertas, hojas de ventana, etc.)<br />
						•	PRODUCTOS QUÍMICOS<br />
						•	RADIOS<br />
						•	REFLECTORES<br />
						•	REGLAS DE OFICINA<br />
						•	RELOJES VARIOS<br />
						•	REMOS (palas, etc.)<br />
						•	ROLDANAS<br />
						•	SALVAVIDAS (chalecos, balsas, etc.)<br />
						•	SERVICIOS DE MESA (platos, piezas de porcelana y loza, cristalería, pocillos, etc.)<br />
						•	SIERRAS Y CEPILLADORAS MECÁNICAS<br />
						•	SILLAS<br />
						•	SILLAS DE RUEDAS<br />
						•	SILLAS PUPITRES<br />
						•	SOPORTES Y MANIPULADORES PARA SOLDADURAS (mesas para soldar)<br />
						•	SUMINISTROS Y ACCESORIOS VARIOS PARA SOLDAR (hierro p/soldar, varillas, soldadores, etc.)<br />
						•	TALABARTERIA, ARNESES, LÁTIGOS (equipos relacionados p/animales)<br />
						•	TANQUES (p/almacenamiento de agua p/uso doméstico)<br />
						•	TELESCOPIO<br />
						•	TELEVISORES<br />
						•	TERCIADOS Y ENCHAPADOS (placas, chapas)<br />
						•	TERMÓMETROS CLÍNICOS<br />
						•	TIJERAS<br />
						•	TIMBRES (dispositivo sonoro)<br />
						•	TORNOS<br />
						•	TORRES (estructuras prefabricadas)<br />
						•	UNIDADES CENTRALES DE PROCESO (computadoras)<br />
						•	VALIJAS<br />
						•	VARIOS (solamente aquellos efectos que no puedan ser clasificados en ninguna clase de las existentes)<br />
						•	VEHICULOS DE TRACCIÓN A SANGRE Y ACOPLADOS PARA GRANJA (coches, carruajes, carros, trineos, etc.)<br />
						•	VENTILADORES (ventiladores de pie y de pared, extractores, circuladores de aire)<br />
					</p>
					<h3>TÍTULO III.</h3>
					<h4>De la identificación de los bienes muebles</h4>
					<p>
						Art. 11º - Cada bien mueble durante su existencia como tal, se identificará por una codificación de dominio formada por 
						letras y números. Dicha codificación deberá ser reproducida en una placa de identificación cuya naturaleza y lugar de 
						colocación será reglamentado por el Organismo de Aplicación. La autoridad de aplicación podrá establecer que ese código 
						sea común a todos los muebles pertenecientes a una misma persona, como así también, instrumentar otros medios de 
						identificación que considere viables y convenientes.
					</p>
					<p>
						Art. 12º - Las características de la placa de identificación prevista en el artículo anterior, serán determinadas por la 
						reglamentación, dentro del sistema de combinación de letras y números que el Organismo de Aplicación disponga.
					</p>	
					<p>
						Art. 13º - La reglamentación determinara la forma de aplicar el sistema único de individualización, de conformidad a lo
						establecido en el art. 10.
					</p>
					<h3>TÍTULO IV.</h3>
					<h4>Disposiciones Generales</h4>
					<p>
						Art 14º - El propietario del bien que resuelva retirarlo definitivamente del uso por no estar en condiciones de servir 
						para su destino especifico, deberá dar inmediata cuenta a la autoridad registral, quien procederá a retirar el titulo 
						respectivo y practicara las anotaciones pertinentes en el registro.
					</p>
					<p>
						Art. 15º - El propietario que resuelva desarmar el bien de su propiedad para usar el material por partes, alterando el 
						destino natural deberá comunicarlo a la autoridad competente con las mismas previsiones dispuestas en el artículo anterior.
					</p>
					<p>
						Art. 16º - El registro de Bienes Muebles tiene como finalidad Inscribir y dar publicidad a los documentos donde se 
						constituyan, se modifiquen, se declaren o se extingan derechos sobre bienes muebles inscribibles, teniendo como objetivo 
						a futuro anotar al margen de su inscripción los documentos expedidos por autoridades competentes tales como embargos, 
						infracciones y demás providencias cautelares relativas a esos bienes, e inscribir y dar publicidad a los gravámenes 
						prendarios que los afecten. Al mismo tiempo, la inscripción registral tiene como finalidad permitir la fácil identificación 
						del bien ante la hipótesis de hurto o robo sobre la misma de conformidad al código de identificación que este posee. 
						Asimismo, si el bien hubiese sido hurtado o robado, el propietario podrá solicitar su recupero acreditando tener posesión 
						del mismo por el hecho de la inscripción registral.
					</p>
					<p>
						Art. 17º - La inscripción de los bienes en el Registro, contiene implícita la aceptación, por parte del inscripto, de que 
						el Sr. Carlos Ariel BOTTA y/o quien el autorice en un futuro este facultada para poner a disposición de la autoridad 
						policial, los archivos existentes en el Registro a los efectos de coadyuvar en el esclarecimiento de hechos ilícitos o 
						de cualquier procedimiento sobre el cual la policía requiera tal información.
					</p>
					<p>
						Art. 18º - Cualquier situación no prevista en el presente, será resuelta por el Sr. Carlos Ariel BOTTA y/o quien el 
						autorice en un futuro y, si el caso fuere susceptible de afectar derechos de la persona inscripta, se citará a esta 
						para que, previo a la resolución, pueda obrar conforme lo estime conveniente a sus intereses.
					</p>
					<p>
						Art. 19º - La creación, desarrollo y funcionamiento del Registro, obedece a principios de buena fe, probidad, honradez y 
						en defensa de los derechos de todos los vecinos, en un espíritu de colaboración y solidaridad, con la noble finalidad de 
						constituir al menos un paliativo, ante el auge de hechos delictivos, todo dentro del marco de las facultades otorgadas por 
						el estatuto del Sr. Carlos Ariel BOTTA y/o quien él autorice en un futuro.
					</p>
				</div>
			</div>
		</Layout>
	)
};

export default TermsAndConditions;