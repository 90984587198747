import React from 'react';

export const GoogleMapsEmbed = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d852.042578452457!2d-63.63144311427202!3d-31.32666922474543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDE5JzM1LjIiUyA2M8KwMzcnNTMuOCJX!5e0!3m2!1ses-419!2sar!4v1707146750619!5m2!1ses-419!2sar"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Maps"
    ></iframe>
  );
};
