import Layout from '../../hocs/Layout';
import { GoogleMapsEmbed } from '../../components/aditional/GoogleMapsEmbed.js';
import { ContactForm } from '../../components/aditional/ContactForm.js';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";
import { AnimatedText } from '../../components/aditional/AnimatedText.js';
import '../../styles/static-pages.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {

	useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    

	return (
		<Layout>
			<div className='container-contact'>
				<div className='contact-form-section'>
					<ContactForm title='Envíanos tu mensaje' 
					text='Si está interesado en saber más sobre la forma en que trabajamos, tiene una propuesta comercial o está interesado en realizar una compra, nos encantaría saber de usted.' />
				</div>
				<div className='contact-title'>
					<AnimatedText text='Contáctenos' className='contact-title-animated' />
				</div>
				<div className='section-contact'>
					<div className='contact-black-info'>
						<div className='container-grid-contact'>
							<div className='container-contact-items'>
								<FaMapMarkerAlt className='contact-icon' />
								<h4>DIRECCIÓN:</h4>
								<p className='text-contact-item'>Pasaje San José 926, Río Primero, Córdoba</p>
							</div><div className='container-contact-items'>
							<FaPhoneAlt className='contact-icon' />
							<h4>ENVÍA TU MENSAJE:</h4>
							<p className='text-contact-item'>+54 9 3574-461111</p>
						</div>
							
						</div>
						<div className='container-contact-items'>
							<FaEnvelope className='contact-icon' />
							<h4>EMAIL:</h4>
							<p className='text-contact-item'>contacto@registrobienesmuebles.com.ar</p>
						</div>
						<div className='container-contact-items'>
							<p className='text-contact-item'>A través de nuestras redes sociales</p>
							<div className='container-social-networks'>
								<Link to='https://www.facebook.com/profile.php?id=61560476252809' className='contact-social'><FaFacebook /></Link>
								<Link to='https://www.instagram.com/registro_de_bienes_muebles?igsh=Y2dzZWh5Nmg5emtu&utm_source=qr' className='contact-social-instagram'><FaInstagram /></Link>
							</div>
						</div>
					</div>
					<div className='container-maps'>
						<GoogleMapsEmbed />
					</div>
				</div>
				<WhatsAppButton />
			</div>
		</Layout>
	);
};

export default Contact