import { useState, useEffect } from 'react';
import '../../styles/cart.css';
import { IoMdClose, IoIosRefresh } from "react-icons/io";



const CartItem = ({
	item,
	count,
	update_item,
	remove_item,
	render,
	setRender,
	setAlert,
}) => {

	const [formData, setFormData] = useState({
		item_count: 1
	});
	const { item_count } = formData;

	useEffect(() => {
		if (count) {
		setFormData({...formData, item_count: count});
	}
	}, [count]);

	const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

	const onSubmit = e=> {
		e.preventDefault();

		const fetchData = async () => {
			try {
				await update_item(item, item_count);
				setRender(!render)

			} catch (err) {

			}
		}

		fetchData();
	}

	const removeItemHandler = async () => {
		await remove_item(item);
		setRender(!render);
	}

	return (
		<div className='container-cart-item'>
			<div className='cart-item-image'>
				<img src={item.good.main_image} />
			</div>
			<div className='cart-item-info'>
				<div className='cart-item-header'>
					<div className='item-good-info'>
						<p className='title-good-info'>{item.good.name}</p>
						<p>$ {item.good.price}</p>
						<p>{item.good.brand}</p>
						<p>{item.good.model}</p>
					</div>
					<form onSubmit={e => onSubmit(e)} className='container-form-count'>
						<div className='content-count'>
							<select
								name='item_count'
								onChange={e => onChange(e)}
								value={item_count}
								className='select-count'
							>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
								<option>6</option>
								<option>7</option>
								<option>8</option>
								<option>9</option>
								<option>Mas de 9</option>
							</select>
							<button type='submit' className='update-button'>
								Actualizar cantidad
							</button>
						</div>
					</form>
					<div className='button-remove-contain'>
						<button className='button-remove' onClick={removeItemHandler}>
							<IoMdClose />
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CartItem