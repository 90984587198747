import React from 'react';
import { useState } from 'react';
import '../../styles/components.css';

export const FAQ = () => {
	const [answersVisible, setAnswersVisible]=useState({});

	const toggleAnswer = (index) => {
		setAnswersVisible({
			...answersVisible,
			[index]: !answersVisible[index]
		});
	};

	return (
		<div className='container-faq'>
			{faqData.map((item, index) => (
				<div className='faq-item'>
					<button className='faq-button' onClick={() => toggleAnswer(index)}>{item.question}</button>
					{answersVisible[index] && <p className='faq-text'>{item.answer}</p>}
				</div>
			))}
		</div>
	);
};

const faqData = [
	{
    	question: "¿Qué son los bienes muebles y qué artículo del Código Civil lo define?",
    	answer: "El Art. 227 del Código Civil y Comercial y ss., refiere a que las cosas muebles son aquellas a las que se pueden transportar de un lugar a otro, o sea moviéndose por si mismas, sea que se muevan por fuerza externa, con excepción de las cosas que sean accesorias de los inmuebles. Son objetos o elementos tangibles y material que puede ser trasladado fácilmente de un lugar a otro manteniendo su integridad y por lo tanto brindando exactamente la misma utilidad."
  	},
	{
    	question: "¿Con el grabado de elementos estamos validando propiedad de ese elemento?",
    	answer: "Quien trae a grabar el elemento para grabar el CODIGO QR, dice ser el único propietario y tiene responsabilidad absoluta sobe ese elemento. El comprador para acreditar la propiedad deberá exhibir la factura, y tener la posesión de buena fe sobre la cosa. La posesión de buena fe sobre cosas muebles equivale a título y produce efectos de título. En ningún caso se le va a requerir la boleta de compra, o que certifique la propiedad, solo su tenencia lo acredita como dueño del bien. El Art. 1911 del Código Civil establece que se presume, salvo prueba en contrario, que es posedor quien ejerce un pedor de hecho sobre una cosa. Es por ello que el Sistema de REGISTRO DE BIENES MUEBLES solo proporciona identificación, la propiedad la manifiesta bajo declaración jurada, en el acta de registración, quien expone esos bienes; de no poseer boleta o instrumento legal que lo acredite como propietario de dicho bien. El comprador para acreditar la propiedad deberá exhibir la factura, y tener la posesión de buena fe sobre la cosa. La posesión de buena fe sobre cosas muebles equivale a titulo y produce efectos de titulo. El artículo 1911 contiene una presuncion jure et de jure según la doctrina"
  	},
  	{
    	question: "¿Qué elementos puedo registrar y solicitar el código de identificación para que sea grabado?",
    	answer: "Cualquier elemento denominado bien mueble que la persona posea y considere, y los que no quiere, no los graba, siempre y cuando no exista un registro para tal elemento, caso de automotores."
  	},
  	{
    	question: "¿Qué es declarar bajo juramento?",
    	answer: "Lo hace quien registra bienes y no tiene como acreditar la propiedad del bien por ningún instrumento legal, caso boleta, etc. y que son de su exclusiva propiedad."
  	},
  	{
    	question: "¿Como saber que el registro es legal?",
    	answer: "En primera instancia se verificó su validez y cumplimiento con las leyes y regulaciones pertinentes, esto implicó asegurarnos que las fuentes utilizadas, provengan de instituciones públicas autorizadas para llevar a cabo este tipo de registro, asi como el grabado automotor, marcas y señales de animales vacunos. Fue crucial la documentación que garantiza que cumpla con los registros legales establecidos como estatuto, actas de registro, franquicias, entre otras. En cuanto a la consulta profesional, se buscó asesoramiento en el estudio del Dr. Frontera, especialista en Derecho Civil y Comercial, quien proporcionó la investigación legal, su opinión experta nos dio el respaldo legal necesario para llevar adelante el Registro de Bienes Muebles. Además, incluyo una investigación para obtener información de algún registro similar y no encontró nada en su búsqueda, determinando que este registro es algo inédito"
  	},
  	{
    	question: "¿Puede identificar bienes en distintos momentos?",
    	answer: "Si puede hacerlo, le asigna un código de identificacion por objeto y a los que en el futuro adquiera, puede ir registrándolo cada vez que lo crea conveniente."
  	},
];

export const FAQFranchise = () => {
	const [answersVisible, setAnswersVisible]=useState({});

	const toggleAnswer = (index) => {
		setAnswersVisible({
			...answersVisible,
			[index]: !answersVisible[index]
		});
	};

	return (
		<div className='container-faq'>
			{faqFranchiseData.map((item, index) => (
				<div className='faq-item'>
					<button className='faq-button' onClick={() => toggleAnswer(index)}>{item.question}</button>
					{answersVisible[index] && <p className='faq-text'>{item.answer}</p>}
				</div>
			))}
		</div>
	);
};

const faqFranchiseData = [
	{
    	question: "¿Cómo se calcula la ganancia del 30%?",
    	answer: "La ganancia del 30% se aplica sobre el costo total del grabado, asegurando una compensación justa y significativa por cada servicio realizado."
  	},
	{
    	question: "¿Cuál es el tiempo de vida útil de las máquinas láser?",
    	answer: "Las máquinas tienen una vida útil promedio de 10,000 a 15,000 horas de uso, ofreciendo durabilidad y eficiencia a largo plazo."
  	},
  	{
    	question: "¿Qué incluye la capacitación?",
    	answer: "La capacitación cubre el uso técnico de las máquinas láser, técnicas de grabado, servicio al cliente, y aspectos administrativos del negocio."
  	},
  	{
    	question: "¿Qué sucede si mi solicitud es rechazada?",
    	answer: "Si tu solicitud es rechazada, recibirás una explicación detallada del motivo y podrás volver a aplicar en el futuro con las mejoras sugeridas."
  	}
];