import { Link } from 'react-router-dom';
import { FaRegEnvelope, FaPhoneAlt, FaFacebook, FaInstagram } from "react-icons/fa";
import '../../styles/navigation.css';


const Footer = () => {
	return (
		<div className='container-footer'>
			<div className='container-privacy'>
				<Link to='/privacy-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Privacidad</Link>
				<Link to='/terms-and-conditions' style={{textDecoration:'none'}} className='privacy-links'>Términos y Condiciones</Link>
				<Link to='/cookies-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Cookies</Link>
				<Link to='/billing-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Cobros</Link>
			</div>
			<div className='footer-social-network'>
				<Link to='https://www.facebook.com/profile.php?id=61560476252809' className='footer-social-facebook'><FaFacebook /></Link>
				<Link to='https://www.instagram.com/registro_de_bienes_muebles?igsh=Y2dzZWh5Nmg5emtu&utm_source=qr' className='footer-social-instagram'><FaInstagram /></Link>
			</div>
			<div className='container-footer-contact'>
				<h4 className='contact-title-footer'>CONTÁCTANOS</h4>
				<p className='contact-text'><FaRegEnvelope />contacto@registrobienesmuebles.com.ar</p>
				<p className='contact-text'><FaPhoneAlt />+54 9 3574-461111</p>
			</div>
			<div>
				<span className='container-rights'>RBM {new Date().getFullYear()} &copy; Todos los Derechos Reservados.</span>
			</div>
		</div>
	)
}

export default Footer;