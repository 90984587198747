import Layout from '../hocs/Layout';
import { Link } from 'react-router-dom';
import { GoLightBulb } from "react-icons/go";
import { WhatsAppButton } from '../components/aditional/WhatsApp'
import { FaAngleDoubleRight, FaUserCheck, FaUpload, FaMapMarkerAlt, FaQrcode } from "react-icons/fa";
import { AnimatedText } from '../components/aditional/AnimatedText.js';
import '../styles/static-pages.css'
import { useEffect } from 'react';
import { ContactForm } from '../components/aditional/ContactForm.js';
import { connect } from 'react-redux';


const Home = ({
	isAuthenticated,
}) => {

	useEffect(() => {
      window.scrollTo(0,0)
    }, [])


	return (
		<Layout>
			<div className='container-main'>
				<div className='banner-main'>
					<div className='container container-banner'>
						<div className='container-main-title'>
							<AnimatedText text='Registra tu mundo en un solo click' className='animated-title' />
							<p className='main-text'>A través de códigos QR tendrás toda la información de tus bienes</p>
						</div>
					</div>
				</div>
			</div>
			{isAuthenticated && <div className='container-call-to-action'>
				<div className='call-to-action-text'>
					<h3>No pierdas tiempo</h3>
					<p>El uso de códigos QR para vincular la identidad de un objeto, es una herramienta poderosa para proteger y rastrear pertenencias, proporcionando una capa adicional de seguridad y verificación.</p>
					<Link to='/goods/upload-good' style={{textDecoration:'none'}} className='about-link'>Registralos ahora</Link>
				</div>
				<div className='call-to-action-image'>
					<img src={require(`../images/call-banner.png`)} />
				</div>
			</div>}
			<div className='container-dark'>
				<div className='container container-services'>
					<div className='container-service-title'>
						<h3 className='process-title'>Nuestros servicios</h3>
					</div>
					<div className='container-service-paragraph'>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>REGISTRA TUS BIENES</h3>
								<p className='color-text'>Puedes registrar tus bienes desde un telefono celular, computadora, o tablet para evidenciar la titularidad y los derechos asociados.</p>
							</div>
						</div>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>CONSERVAMOS TU INFORMACIÓN</h3>
								<p className='color-text'>Generá códigos QR únicos para cada uno de tus bienes, los cuales podrás escanear para acceder rápidamente a la información personal y detallada del bien en nuestro sistema, vinculando cada producto con tu información respaldada en nuestro sitio web.</p>
							</div>
						</div>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>IDENTIFICA TUS BIENES</h3>
								<p className='color-text'>La consulta de los bienes a través de códigos QR permite identificar de manera eficaz y rápida a la persona que realizó el registro.</p>
							</div>
						</div>
						{/*<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>LISTAMOS TUS BIENES</h3>
								<p className='color-text'>El Registro de Bienes Muebles te proporciona un listado de la información que has cargado sobre tus bienes, vinculada a tu información personal, de manera que pueda ser utilizada en caso de robo, extravío, auditoría y asuntos legales, para contribuir eficazmente.</p>
							</div>
						</div>*/}
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>NO VALIDAMOS PROPIEDAD</h3>
								<p className='color-text'>Nosotros no validamos la propiedad de los bienes registrados, ya que la propiedad manifestada bajo declaración jurada en el acta de registro por el propietario; nuestro rol se limita a proporcionar identificación a través del código QR.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-aboutus'>
				<div className='about-text'>
					<h3>Una herramienta y una idea <GoLightBulb /></h3>
					<p>Aprende cómo nuestro registro de bienes muebles está ayudando a proteger los activos de individuos, empresas y entidades públicas</p>
					<Link to='/about' style={{textDecoration:'none'}} className='about-link'>Sobre Nosotros</Link>
				</div>
			</div>
			<div className='container-home-franchises'>
				<div className='contain-home-franchises'>
					<h3>¿Quieres formar parte de nuestro equipo?</h3>
					<p>Descubre la oportunidad de formar parte de nuestra exitosa red de franquicias de Registro de Bienes Muebles, donde la rentabilidad y el respaldo continuo te llevarán al éxito empresarial</p>
					<p>¡Conoce más sobre cómo unirte y aprovechar esta inversión rentable!</p>
					<Link to='/franchises' className='about-link' style={{textDecoration: 'none'}}>Conoce más</Link>	
				</div>
				<div className='container-franchise-image'>
					<img src={require(`../images/franchise.jpg`)} />
				</div>
			</div>
			<div className='container-gray'>
				<div className='container container-process'>
					<div className='container-process-title'>
						<h3 className='process-title'>Registra tus bienes de manera sencilla</h3>
					</div>
					<div className='process-templates'>
						<div className='process-items'>
							<div className='process-icon'>
								<FaUserCheck />
							</div>
							<div className='process-text'>
								<p>Crea una cuenta utilizando tus datos reales y tu tarjeta de identificación</p>
							</div>
						</div>
						<div className='process-items2'>
							<div className='process-text'>
								<p>Carga tus bienes con descripción, imagenes, marca, modelo, etc</p>
							</div>
							<div className='process-icon'>
								<FaUpload />
							</div>
						</div>
						<div className='process-items'>
							<div className='process-icon'>
								<FaMapMarkerAlt />
							</div>
							<div className='process-text'>
								<p>Acercate con tus bienes a la oficina de registro mas cercana</p>
							</div>
						</div>
						<div className='process-items2'>
							<div className='process-text'>
								<p>Allí se grabara el código QR de tu bien</p>
							</div>
							<div className='process-icon'>
								<FaQrcode />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-contact-home'>
				<ContactForm title='Contáctanos' 
				text='Si está interesado en saber más sobre la forma en que trabajamos, tiene una propuesta comercial o está interesado en realizar una compra, nos encantaría saber de usted.' />
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
}) (Home)