import Layout from '../../hocs/Layout';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const BillingPolicies =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Política de Cobros</h2>
					<h3>Cobros por Grabado de Bienes</h3>
					<p><FaCircle className='icon-policies' />Tarifa por Grabado: Se cobrará una tarifa fija por cada grabado realizado, independientemente del tipo de bien, antigüedad 
					o precio del bien. La tarifa está basada únicamente en la cantidad de grabados.</p>
					<div className='policies-spacing'>
						<p><FaRegCircle className='icon-policies' />Precio por Grabado: $ 4.500 (Pesos cuatro mil quinientos)</p>
					</div>
					<p><FaCircle className='icon-policies' />Grabado de bienes repetitivos: En caso de bienes repetitivos, sea ejemplo, sillas plásticas, mesas, 
					caballetes, tablones, etc. El precio por grabado se mantendrá por cada bien, pero el cobro por mantenimiento se tendrá en cuenta como 1(un) 
					bien por cada 10 (diez) bienes repetitivos.</p>
					<h3>Cobros por Mantenimiento</h3>
					<p><FaCircle className='icon-policies' />Tarifa por mantenimiento: Se cobrará una tarifa por mantenimiento basada en la cantidad de bienes 
					registrados donde habrá 3 (tres) categorías en base a la cantidad de bienes.</p>
					<div className='policies-spacing'>
						<p><FaRegCircle className='icon-policies' />Hasta 50 bienes: El costo de mantenimiento por registrar hasta 50 (cincuenta) bienes será 
						de U$D 2 (dólares dos) por mes.</p>
						<p><FaRegCircle className='icon-policies' />Entre 51 y 100 bienes: El costo de mantenimiento por registrar entre 51 (cincuenta y uno) 
						hasta 100 bienes será de U$D 3 (dólares tres) por mes.</p>
						<p><FaRegCircle className='icon-policies' />Más de 100 bienes: El costo de mantenimiento por registrar más de 100 bienes será de U$D 5 
						(dólares cinco) por mes.</p>
					</div>
					<p><FaCircle className='icon-policies' />Mantenimiento Mensual: Se ofrecerá a los clientes la opción de pagar una tarifa mensual para 
					cubrir los gastos de mantenimiento.</p>
					<div className='policies-spacing'>
						<p><FaRegCircle className='icon-policies' />Precio Mensual: El precio de la tarifa mensual dependerá de la cantidad de bienes 
						registrados, como se explicita en el punto anterior.</p>
					</div>
					<p><FaCircle className='icon-policies' />Mantenimiento Anual: Alternativamente, los clientes pueden optar por un pago anual, que ofrece un 
					descuento en comparación con la tarifa mensual.</p>
					<div className='policies-spacing'>
						<p><FaRegCircle className='icon-policies' />Precio Anual: El mantenimiento anual tendrá un descuento del 20% (veinte por ciento) del 
						monto de 12 (doce) meses de acuerdo a la categoría de servicio.</p>
					</div>
					<h3>Política de Franquicias</h3>
					<p><FaCircle className='icon-policies' />Beneficio de Franquiciantes: Los franquiciantes tendrán un beneficio del 30% del precio de grabado 
					del bien.</p>
					<div className='policies-spacing'>
						<p><FaRegCircle className='icon-policies' />Cobro y Mantenimiento: Los franquiciantes serán responsables del cobro de sus clientes y 
						retendrán el 30% de los ingresos por grabados. El otro 70% será gestionado a través de nuestra plataforma web.</p>
					</div>
					<p><FaCircle className='icon-policies' />Condiciones Adicionales: Todos los cobros y tarifas deberán seguir las políticas y términos 
					establecidos en este documento.</p>
					<h3>Términos Generales</h3>
					<p><FaCircle className='icon-policies' />Métodos de Pago: Aceptamos pagos a través de tarjetas de débito o crédito, transferencias, cupón 
					de pago, y pagos en efectivo.</p>
					<p><FaCircle className='icon-policies' />Facturación: Las facturas se emitirán mensual o anualmente, según la opción de mantenimiento 
					seleccionada.</p>
					<p><FaCircle className='icon-policies' />Política de Reembolsos: Los reembolsos únicamente aplican antes de efectuar el grabado del bien, 
					luego del grabado de un bien, no hay posibilidad de reembolso alguno.</p>
					<h3>Contacto</h3>
					<p>Para cualquier consulta relacionada con los cobros, por favor contacte a nuestro equipo de soporte a través de 
					contacto@registrobienesmuebles.com.ar</p>
				</div>
			</div>
		</Layout>
	)
};

export default BillingPolicies;