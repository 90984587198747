import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/* Static Pages*/
import Home from './containers/Home';
import Franchises from './containers/pages/Franchises';
import Error404 from './containers/errors/Error404';
import About from './containers/pages/About';
import Contact from './containers/pages/Contact';
import Cart from './containers/pages/Cart';
/* Profile */
import Profile from './containers/profile/Profile';
import EditProfile from './containers/profile/EditProfile';
/* Goods */
import Goods from './containers/Goods';
import EditGood from './containers/goods/EditGood';
import GoodDetail from './containers/goods/GoodDetail';
import UploadNewGood from './containers/goods/UploadNewGood';
import CookiesPolicies from './containers/policies/CookiesPolicies';
import PrivacyPolicies from './containers/policies/PrivacyPolicies';
import BillingPolicies from './containers/policies/BillingPolicies';
import TermsAndConditions from './containers/policies/TermsAndConditions';
import RegisterAct from './components/PDF/RegisterAct';
import RegisterActGood from './components/PDF/RegisterActGood';
import Activate from './containers/auth/Activate';
import ResetPassword from './containers/auth/ResetPassword';
import ResetPasswordConfirm from './containers/auth/ResetPasswordConfirm';
import Login from './containers/auth/Login';
import Signup from './containers/auth/Signup';
import UploadID from './containers/auth/UploadID';
import Checkout from './containers/payment/Checkout';


function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    {/* Error Display */}
                    <Route path='*' element={<Error404 />} />

                    {/* Static Pages */}
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/about' element={<About />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/franchises' element={<Franchises />} />

                    {/* Authentication */}
                    <Route exact path='/activate/:uid/:token' element={<Activate />} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/signup' element={<Signup />} />
                    <Route exact path='/signup/:userId/upload-card' element={<UploadID />} />
                    <Route exact path='/profile' element={<Profile />} />
                    <Route exact path='/profile/edit' element={<EditProfile />} />
                    <Route exact path='/reset_password' element={<ResetPassword />} />
                    <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />

                    {/* Good Pages */}
                    <Route exact path='/goods' element={<Goods />}/>
                    <Route exact path='/goods/good/:goodId' element={<GoodDetail />}/>
                    <Route exact path='/goods/upload-good' element={<UploadNewGood />}/>
                    <Route exact path='/goods/good/:goodId/edit' element={<EditGood />}/>
                    <Route exact path='/cart' element={<Cart />} />
                    <Route exact path='/checkout' element={<Checkout />} />

                    {/* Polices */}
                    <Route exact path='/cookies-policies' element={<CookiesPolicies />}/>
                    <Route exact path='/privacy-policies' element={<PrivacyPolicies />}/>
                    <Route exact path='/billing-policies' element={<BillingPolicies />}/>
                    <Route exact path='/terms-and-conditions' element={<TermsAndConditions />}/>
                    <Route exact path='/register-act' element={<RegisterAct />}/>
                    <Route exact path='/goods/good/:goodId/register-act' element={<RegisterActGood />}/>
                </Routes>
            </Router>
        </Provider>
    );
};

export default App;
