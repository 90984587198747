import {
	GET_COSTS_SUCCESS,
    GET_COSTS_FAIL,
} from '../actions/types'


const initialState = {
    costsPrice: null,
}

export default function Costs(state = initialState, action) {
    const { type, payload } = action;

	switch(type) {
        case GET_COSTS_SUCCESS:
            return {
                ...state,
                costsPrice: payload.price
            }
        case GET_COSTS_FAIL:
            return {
                ...state,
                costsPrice: null
            }
        default:
            return state
    }
}