import { Fragment } from 'react';
import { connect } from 'react-redux';
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import '../styles/components.css';


function Alert ({ alert }) {
	const displayAlert = () => {
		if (alert !== null) {
			return (
				<div className='container-alert'>
					<div>
						{alert.alertType === 'good' ? (
        					<FaRegCheckCircle className="correct-alert" />
      						) : (
        					<FaRegTimesCircle className="incorrect-alert" />
      					)}
					</div>
					<div>
						<p className={`${alert.alertType}`}>{alert.msg}</p>
					</div>
				</div>
			)
		} else {
			<Fragment></Fragment>
		}
	}

	return (
		<Fragment>
			{displayAlert()}
		</Fragment>
	);
}

const mapStateToProps = state => ({
	alert: state.Alert.alert
})

export default connect(mapStateToProps)(Alert)