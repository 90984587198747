import Layout from '../../hocs/Layout';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { get_good, get_profile_good } from "../../redux/actions/good";
import '../../styles/goods.css'
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Gallery } from '../../components/goods/Gallery';
import { get_items, get_total, get_item_total, add_item } from '../../redux/actions/cart';


const GoodDetail =({
    get_good,
    good,
    get_profile_good,
    profile,
    isAuthenticated,
    user,
    get_items,
	get_total,
	get_item_total,
	add_item,
	items
})=>{

    const params = useParams()
    const goodId = params.goodId
    const [goToPay, setGoToPay] = useState(false);
    const [verifyUser, setVerifyUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const [redirectToCart, setRedirectToCart] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      window.scrollTo(0,0)
        get_good(goodId)
        get_profile_good(goodId)
    }, [])

    const addToCart = async () => {
		if (good && good !== null && good !== undefined) {
			setLoading(true);
			await add_item(good);
			await get_items();
			await get_total();
			await get_item_total();
			setLoading(false);
		}
	}

	useEffect(() => {
		if (goodId && items) {
			 const goodIds = items.map(item => item.good.id.toString());
			 const goodIdString = goodId.toString();
        	if (goodIds.includes(goodIdString)) {
            	setRedirectToCart(true);
        	}
		}
	}, [goodId, items])

    const statusMap = {
  		posesion: 'En posesión',
  		extraviado: 'Extraviado',
  		robado: 'Robado - Sin denunciar',
  		robado_de: 'Robado - Denunciado'
	};

	const payStatusMap = {
  		pendiente: 'Pendiente de pago',
  		pagado: 'Pagado'
	};

	const recordedStatusMap = {
  		pendiente: 'No grabado',
  		proceso: 'En proceso',
  		finalizado: 'Grabado'
	};

	useEffect(() => {
		if (user) {
        	setUserId(user.id)
        }
	}, [user])

	useEffect(() => {
		if (good) {
			if (good.pay_status !== 'pagado') {
				setGoToPay(true)
			}
		}
	}, [good])

	useEffect(() => {
	    if (userId && good) {
	        if (userId === good.owner) {
	            setVerifyUser(true);
	        }
	    }
	}, [userId, good]);

    return(
        <Layout>
        	<div className='good-detail'>
        	{isAuthenticated && <Link to='/goods' className='return-button' style={{textDecoration: 'none'}}><FaArrowLeft />Regresar</Link>}
        	
    			<div className='container container-details'>
	    			<div className='good-profile'>
		    			<div className='container-info'>
			    			<div className='good-profile-img'>
			    				<img src={profile && profile.photo} />
			    			</div>
			    			<div className='good-profile-info'>
			    				<h3>Actualmente pertenece a:</h3>
			    				<p className='profile-good-text'>Nombre y Apellido: <span>{profile && profile.full_name}</span></p>
			    				<p className='profile-good-text'>Email: <span>{profile && profile.email}</span></p>
			    				<p className='profile-good-text'>Número de Teléfono: <span>{profile && profile.phone}</span></p>
			    				<p className='profile-good-text'>Dirección: <span>{profile && profile.address}</span></p>
			    			</div>
		    			</div>
		    			<div className='good-status'>
		    				<h4 className='actual-status'>Estado Actual:</h4>
		    				<p className='actual-status'>{good && statusMap[good.good_status]}</p>
		    				{isAuthenticated && 
		    					<>
			    					<p className='actual-status'>{good && payStatusMap[good.pay_status]}</p>
			    					<p className='actual-status'>{good && recordedStatusMap[good.recorded_status]}</p>
			    				</>
		    				}
		    			</div>
		    			<div className='contain-pay-link'>
		    				{
		    					isAuthenticated && 
		    					verifyUser && 
		    					goToPay && (
									redirectToCart ? (
										<Link to={`/cart`} className='go-to-pay' style={{textDecoration: 'none'}}>Ver carrito</Link>
									):(
										<button className='add-cart-button' onClick={addToCart}>Añadir al carrito</button>
									)
								)	
		    				}
		    			</div>
		    			{/*<div className='previous-owner'>
		    				<h4 className='previous-owner-title'>Dueño anterior: <span>Juan Fulano</span></h4>
		    			</div>*/}
		    		</div>
            		<div className='good-items'>
		                <h3 className='good-item-title'>Detalles del bien:</h3>
		                <Gallery imageUrl={[good && good.main_image, good && good.back_image]} />
		                <p>Nombre: {good && good.name}</p>
		                <p>Descripción: {good && good.description}</p>
		                <p>Marca: {good && good.brand}</p>
		                <p>Modelo: {good && good.model}</p>
		                <p>Nº de Serie: {good && good.serial}</p>
		                <p>Talla: {good && good.size}</p>
		                <p>Color: {good && good.color}</p>
		                {isAuthenticated && verifyUser && <Link to={`/goods/good/${goodId}/edit`} className='button-details' style={{textDecoration: 'none'}}>Editar</Link>}
		                {isAuthenticated && verifyUser && <Link to={`/goods/good/${goodId}/register-act`} className='button-details' style={{textDecoration: 'none'}}>Acta</Link>}
		            </div>
		        </div>
      		</div>
        </Layout>
    );
};

const mapStateToProps = state => ({
    good: state.Good.good,
    profile: state.Good.profile,
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
    items: state.Cart.items,
})

export default connect(mapStateToProps, {
    get_good,
    get_profile_good,
    get_items,
	get_total,
	get_item_total,
	add_item,
}) (GoodDetail)