import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { remove_item, update_item, get_items, get_total, get_item_total } from "../../redux/actions/cart";
import { useEffect, useState } from 'react';
import CartItem from '../../components/cart/CartItem';
import { Link } from 'react-router-dom';
import '../../styles/cart.css';
import { setAlert } from '../../redux/actions/alert';
import { Navigate } from 'react-router';


const Cart = ({
	get_items,
	items,
	get_total,
	get_item_total,
	amount,
	total_items,
	isAuthenticated,
	remove_item,
	update_item,
	setAlert,
}) => {

	const [render, setRender] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        get_items()
        get_total()
        get_item_total()
    }, [render])

    const showItems = () => {
    	return (
    		<div>
				{
					items &&
					items !== null &&
					items !== undefined &&
					items.length !== 0 &&
					items.map((item, index) => {
						let count = item.count;

						return (
							<div key={index}>
								<CartItem
									item={item}
									count={count}
									update_item={update_item}
									remove_item={remove_item}
									render={render}
									setRender={setRender}
									setAlert={setAlert}
								/>
							</div>
						)
					})
				}
			</div>
    	)
    };

    const showWhislistItems = () => {

    }

    if(!isAuthenticated)
        return <Navigate to="/" />

	return (
		<Layout>
			{
				items &&
				items !== null &&
				items !== undefined &&
				items.length !== 0 ? (
					<div className='container-cart'>
						<div className='container-cart-title'>
							<h2>Productos en carrito ({total_items && total_items})</h2>
						</div>
						<div className='container-cart-info'>
							<div>
								{showItems()}
							</div>
							<div className='checkout-items'>
								<h3>Resumen del pedido</h3>
								<div className='checkout-item'>
									<p>Subtotal</p>
									<p>$ {amount && amount.toFixed(2)}</p>
								</div>
								<Link to='/checkout' style={{textDecoration: 'none'}} className='checkout-button'>Ir a pagar</Link>
							</div>
						</div>
					</div>
				) : (
					<div className='container-empty-cart'>
						<div className='container-cart-title'>
							<h2>No hay productos en tu carrito</h2>
						</div>
						<div className='cart-back-to-goods'>
							<Link to='/goods' className='cart-link-to-goods' style={{textDecoration: 'none'}}>Volver a tus bienes</Link>
						</div>
					</div>
				)
			}
		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Cart.items,
	amount: state.Cart.amount,
	total_items: state.Cart.total_items,
})

export default connect(mapStateToProps, {
	get_items,
	get_total,
	get_item_total,
	remove_item,
	update_item,
	setAlert,
}) (Cart)