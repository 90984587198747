import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { upload_good } from '../../redux/actions/good';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import '../../styles/goods.css'
import { useState, useEffect, useRef } from 'react';
import { Oval } from 'react-loader-spinner';
import imageCompression from 'browser-image-compression';
import { FaArrowLeft } from 'react-icons/fa';
import { Navigate } from 'react-router';


const UploadNewGood = ({
	upload_good,
	goodLoading,
	uploadGood,
	goToPayment,
	isAuthenticated
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	const form = useRef(null);
	const initialFormData = Object.freeze({
		name:'',
	    description:'',
	    brand:'',
	    model:'',
	    serial:'',
	    color:'',
	    size:''
	});

	const [formData, setFormData] = useState(initialFormData);
	const mainImageInputRef = useRef(null);
    const backImageInputRef = useRef(null);
    const [mainImage, setMainImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [error, setError] = useState('');
    const [postMainImage, setPostMainImage] = useState(null);
    const [postBackImage, setPostBackImage] = useState(null);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [resetForm, setResetForm] = useState(false);

    useEffect(() => {
      	if (goToPayment && resetForm && !goodLoading) {
			form.current.reset();
			setFormData(initialFormData);
			setMainImage('');
			setBackImage('');
			setPostMainImage(null);
			setPostBackImage(null);
			setResetForm(false);
		}
    }, [goToPayment, resetForm, !goodLoading])
    
    const loadImage = async (event, setImage, inputName) => {
        const file = event.target.files[0];
        const options = {
        	maxSizeMB: 1,
        	maxWidthOrHeight: 1920,
        	useWebWorker: true,
        }
        try {

            if (file) {
            	const compressedFile = await imageCompression(file, options);

            	const compressedImageFile = new File([compressedFile], file.name, {
	                type: file.type,
	                lastModified: file.lastModified,
	            });

		        if (inputName === 'mainImage') {
		        	setPostMainImage({
		        		image: compressedImageFile,
		        	});
		        }
		        if (inputName === 'backImage') {
		        	setPostBackImage({
		        		image: compressedImageFile,
		        	});
		        }

		        const reader = new FileReader();
                reader.onload = () => {
	            	setImage({
		                file: compressedImageFile,
		                previewURL: reader.result
		            });
		        };

		        reader.onerror = () => {
		            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
		        };
			
				reader.readAsDataURL(compressedImageFile);
	        }
        } catch (error) {
            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
        }
    };

    const handleDelete = (imageKey, setImage, setPostImage, inputRef) => {
        setImage(null);
        setPostImage(null);
        inputRef.current.value = '';
    }

    const onSubmit = async (e) =>{
    	e.preventDefault();
    	if (postMainImage) {
        const imageData = {
            mainImage: postMainImage,
            backImage: postBackImage
        };
	    	await upload_good(formData, imageData);
	    	setResetForm(true);
	    }
  	};

  	const handleCheckboxChange = () => {
        setAcceptTerms(!acceptTerms);
    };

  	if(!isAuthenticated)
        return <Navigate to="/" />

	return(
		<Layout>
			<div className='container-upload-form'>
				<form className='good-form' onSubmit={e => onSubmit(e)} ref={form}>
					<Link to='/goods' className='return-button' style={{textDecoration: 'none'}}><FaArrowLeft />Regresar</Link>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
					<h3 className='form-title'>Registrar nuevo bien</h3>
                    <div className='form-section-good'>
                        <label>Nombre*</label>
                        <input
	                        type='text'
	                        name='name'
	                        value={formData.name} 
	    					onChange={(e) => setFormData({ ...formData, name: e.target.value })}
	                        placeholder='Nombre del bien'
	                        className='input-form'
	                        required
	                    />
                    </div>
                    <div className='form-section-good'>
                        <label>Descripción*</label>
                        <input
                        	type='text'
                        	name='description'
                        	value={formData.description} 
    						onChange={(e) => setFormData({ ...formData, description: e.target.value })}
    						placeholder='Realice una breve descripción'
    						className='input-form'
    						required
    					/>
                    </div>
                    <div className='form-section-good'>
                        <label>Marca*</label>
                        <input type='text'
	                        name='brand'
	                        value={formData.brand} 
	    					onChange={(e) => setFormData({ ...formData, brand: e.target.value })}
	                        placeholder='Escriba la marca del bien'
	                        className='input-form'
	                        required
	                    />
                    </div>
                    <div className='form-section-good'>
                        <label>Modelo*</label>
                        <input
                        	type='text'
                        	name='model'
                        	value={formData.model} 
    						onChange={(e) => setFormData({ ...formData, model: e.target.value })}
    						placeholder='Escriba el modelo del bien'
    						className='input-form'
    						required
    					/>
                    </div>
                    <div className='form-section-good'>
                        <label>Nº de Serie</label>
                        <input
                        	type='text'
                        	name='serial'
                        	value={formData.serial} 
    						onChange={(e) => setFormData({ ...formData, serial: e.target.value })}
    						placeholder='Escriba el Nº de Serie del bien'
    						className='input-form'
    					/>
                    </div>
                    <div className='form-section-good'>
                        <label>Color</label>
                        <input
                        	type='text'
                        	name='color'
                        	value={formData.color} 
    						onChange={(e) => setFormData({ ...formData, color: e.target.value })}
    						placeholder='Color del bien'
    						className='input-form'
    						maxlenght='50'
    					/>
                    </div>
                    <div className='form-section-good'>
                        <label>Talla</label>
                        <input
                        	type='text'
                        	name='size'
                        	value={formData.size} 
    						onChange={(e) => setFormData({ ...formData, size: e.target.value })}
    						placeholder='Escriba su talla'
    						className='input-form'
    					/>
                    </div>
                    <p className='required-fields'>Los campos marcados con * son obligatorios</p>
                    <div className="preview">
                        <h3>Vista previa</h3>
                        <div className="image-preview">
                        	<div className='newgood-image-container'>
	                            {mainImage && <div className='preview-image-container'>
	                                <img src={mainImage.previewURL} alt="Preview 1" />
	                                <button type="button" className='delete-button' onClick={() => handleDelete('mainImage', setMainImage, setPostMainImage, mainImageInputRef)}><IoMdClose /></button>
	                            </div>}
								{!mainImage && <div className="placeholder">Imagen principal*</div>}
								<input type="file" name='mainImage' ref={mainImageInputRef} onChange={(e) => loadImage(e, setMainImage, 'mainImage')} />
							</div>
							<div className='newgood-image-container'>
	                            {backImage && <div className='preview-image-container'>
	                                <img src={backImage.previewURL} alt="Preview 2" />
	                                <button type="button" className='delete-button' onClick={() => handleDelete('backImage', setBackImage, setPostBackImage, backImageInputRef)}><IoMdClose /></button>
	                            </div>}
	                            {!backImage && <div className="placeholder">Imagen secundaria</div>}
	                            <input type="file" name='backImage' ref={backImageInputRef} onChange={(e) => loadImage(e, setBackImage, 'backImage')} />
                            </div>
                        </div>
                        <div className='required-gap'>
							<input type='checkbox' onChange={handleCheckboxChange} />
							<p>He leído y acepto los <Link to='/terms-and-conditions' className='privacy-goods' style={{textDecoration:'none'}}>Terminos y condiciones, </Link>la <Link to='/billing-policies' className='privacy-goods' style={{textDecoration:'none'}}>Politica de Cobros</Link> y el contenido del <Link to='/register-act' className='privacy-goods' style={{textDecoration:'none'}}>Acta de registro</Link></p>
                    	</div>
                        {goodLoading ? (
                        	<button type='button' className='submit-button'><Oval
			  					visible={true}
								height="25"
								width="25"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secondaryColor="#ddd"
								/>
							</button>
						) : (acceptTerms ? (
								<button type='submit' className='submit-button'>
									Registrar	
								</button>
							) : (
								<p className='not-button-form'>Registrar</p>
							))
						}
                    </div>
                </form>
            </div>
		</Layout>
	)
};

const mapStateToProps = state => ({
	goodLoading: state.Good.goodLoading,
	uploadGood: state.Good.uploadGood,
	goToPayment: state.Good.goToPayment,
	isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
	upload_good
}) (UploadNewGood)